import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { GatsbyImage } from 'gatsby-plugin-image'

const Execs = () => {
  const people = useStaticQuery(graphql`
    query {
      allWpTeamMember(filter: { teamMembers: { type: { in: "exec" } } }, sort: { fields: menuOrder, order: ASC }) {
        nodes {
          title
          id
          teamMembers {
            jobTitle
          }
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 90, width: 660)
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <section id="execs" className="bg-primary text-center">
      <Container fluid className="px-0">
        <Row className="g-0">
          {people.allWpTeamMember.nodes.map(node => (
            <Col sm="6" md="4" xxl="3" className="exec-column" key={node.id}>
              <div className="exec-tile p-2 p-md-1 p-lg-2 h-100">
                <GatsbyImage
                  image={node.featuredImage.node.localFile.childImageSharp.gatsbyImageData}
                  alt={`${node.name}, ${node.title} at Meati™`}
                  className="exec-image-wrapper mb-1 rounded-6"
                  imgClassName="exec-image rounded-6"
                />
                <h2 className="font-swish">{node.title}</h2>
                <p className="subtitle mb-0">{node.teamMembers.jobTitle}</p>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  )
}

export default Execs
