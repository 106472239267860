import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Accordion } from 'react-bootstrap'
import Bubble from '../svg-icons/bubble'
import BlogCaret from '../svg-icons/blog-caret'

const Founders = () => {
  const people = useStaticQuery(graphql`
    query {
      allWpTeamMember(filter: { teamMembers: { type: { in: "founder" } } }, sort: { fields: menuOrder, order: ASC }) {
        nodes {
          title
          teamMembers {
            jobTitle
            founderAccordion {
              content
              header
            }
          }
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 90, width: 660)
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <section id="founders">
      <Container fluid className="px-0">
        {people.allWpTeamMember.nodes.map((node, index) => {
          const accordionItems = node.teamMembers.founderAccordion.map((item, i) => (
            <Accordion.Item key={i} eventKey={i.toString()}>
              <Accordion.Header as="div">
                <h3 className="font-swish mb-0">{item.header}</h3>
                <BlogCaret />
              </Accordion.Header>
              <Accordion.Body className="px-0">{item.content}</Accordion.Body>
            </Accordion.Item>
          ))

          return (
            <Row className="founder-row" key={`founder-${index}`}>
              <Col sm="6" className="founder-image-column d-flex align-items-start pb-lg-3">
                <div className="founder-image-container">
                  <GatsbyImage
                    image={node.featuredImage.node.localFile.childImageSharp.gatsbyImageData}
                    alt={`${node.title}, ${node.teamMembers.jobTitle} at Meati™`}
                    className="founder-image-wrapper"
                    imgClassName="founder-image"
                  />
                  <Bubble type="founder" text={node.teamMembers.jobTitle} />
                </div>
              </Col>
              <Col sm="6" className="founder-text-column">
                <Container className="pt-1 pb-3 pt-sm-3 pb-sm-4 founder-text-container px-sm-1 px-md-2 px-lg-3 pt-xl-5">
                  <h2 className="font-swish">{node.title}</h2>
                  <Accordion defaultActiveKey={['0']} flush className="founder-accordion">
                    {accordionItems}
                  </Accordion>
                </Container>
              </Col>
            </Row>
          )
        })}
      </Container>
    </section>
  )
}

export default Founders
