import * as React from 'react'

import { StaticImage } from 'gatsby-plugin-image'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Seo from '../components/seo'
import Layout from '../components/layout'

import MainCTA from '../components/sections/main-cta'

import Founders from '../components/sections/team-founders'
import Execs from '../components/sections/team-execs'

const TeamPage = () => {
  return (
    <Layout>
      <Seo title="Meet the Team" />
      <header className="py-2 py-lg-4 py-xl-5" id="teamHero">
        <Container fluid="xxl" className="d-sm-flex flex-column align-items-center">
          <h1 className="mb-1 font-swish fst-italic text-center">
            <span className="text-warning">Meet</span>
            {' '}
            The
            <span className="text-primary">Team</span>
          </h1>
          <Container>
            <Row>
              <Col xs="8" className="d-flex align-items-center">
                <p className="mb-0 pt-1 ps-1 px-md-3 px-lg-5 px-xl-8 fw-bold subtitle">This is: The Good Energy™ Crew — aka the people who are changing the way you eat, one bite at a time.</p>
              </Col>
              <Col xs="4">
                <StaticImage
                  src="../images/mo/meati-happy-orange.png"
                  width={575}
                  quality={95}
                  formats={['AUTO', 'WEBP']}
                  alt="Happy Meati™ character"
                  placeholder="blurred"
                  className="mo-right-container"
                  imgClassName="mo-right-image"
                />
              </Col>
            </Row>
          </Container>
        </Container>
      </header>
      <Founders />
      <Execs />
      <MainCTA bgVariant={`bg-light`} btnVariant={`dark`} />
    </Layout>
  )
}

export default TeamPage
